import { template as template_7c605e81add4409aad7510ef94c68502 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7c605e81add4409aad7510ef94c68502(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
